const initialState = {
    globalValue: null,
  };
  
  const globalReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_GLOBAL_VALUE':
        return {
          ...state,
          globalValue: action.payload,
        };
      case 'SET_USER_ID':
        return {
          ...state,
          userId: action.payload,
        };
      case 'SET_PRODUCT_IN_MEAL':
        return {
          ...state,
          productInMeal: action.payload,
        };
      
      default:
        return state;
    }
  };
  
  export default globalReducer;