import React from 'react';
import { ReactComponent as Img } from './Img/createfood.svg';


const ButtonCreatefood = ({ onClick, children }) => {
    return (
        <button 
            className="products-button" 
            onClick={onClick} 
            style={{ 
                backgroundColor: '#ffffff',
                display: 'flex', // Используем flex для управления элементами
                flexDirection: 'column', // Вертикальное выравнивание
                alignItems: 'center', // Центрируем элементы вертикально
                border: '2px solid #EDEDED', // Задаем толщину, стиль и цвет бордера
                borderRadius: '10px', // Для скругленных углов (опционально)
                height: '115px', // Устанавливаем высоту кнопки
                width: '155px', // Устанавливаем ширину кнопки
                
            }}> 
            {children}
            <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Img style={{ paddingBottom: '5px' }} />
    <p style={{ 
        color: 'black', // Цвет текста
        fontSize: '15px', // Размер шрифта
        fontWeight: '510',
        marginTop: '0' // Отступ сверху для текста
    }}>
        Создать блюдо
    </p>
    </div>
            
        </div>
        
        </button>
    );
};

export default ButtonCreatefood;