import React, { useState,  useEffect, useRef } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserId } from '../actions';
import { Outlet } from 'react-router-dom';

import BottomMenu from './Button/BottomMenu';
import './Menu.css';

import ButtonProducts from './Button/ButtonProducts';
import ButtonMeals from './Button/ButtonMeals';
import ButtonWorkout from './Button/ButtonWorkout';


function Menu() {

  const navigate = useNavigate();

  const userId = useSelector((state) => state.userId);

  const handleClickProducts = () => {
    navigate('/mainmenu/menuproducts');
  };

  const handleClickMeals = () => {
    navigate('/mainmenu/menumeals');
  };

  const handleClickWorkout = () => {
    navigate('/mainmenu/menuworkout');
  };


  return (
    <div className="app-container">
  
    <header className="headerStyle">
      <div className="header_contentStyle">
        <p className="header_textStyle">Главная</p>
      </div>
    </header>
    
     <main style={{ 
          display: 'flex',
          flexDirection: 'column', // или 'row', в зависимости от вашей структуры
          alignItems: 'center', // Горизонтальное выравнивание
          paddingTop: '10px',
          gap: '15px'
          
}}>
             {/* <h1>Тут!</h1>
             <h1>{userId}</h1> */}
     
                <ButtonProducts onClick={handleClickProducts} />
                <ButtonMeals onClick={handleClickMeals}/>
                <ButtonWorkout onClick={handleClickWorkout}/>
          
             {/* < ButtonProducts />
             < ButtonProducts /> */}
             {/* <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1>
             <h1>Тут!</h1> */}
     </main>

     <footer>
           <BottomMenu/>
            
     </footer>
     
    </div>
  );
}

export default Menu;