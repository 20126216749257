import React from 'react';
import './ButtonCreatingMeal.css'; // Импортируем файл стилей

const ButtonCreatingMeal = () => {
  return (
    <button className="button-creatingmeal">
      Создать прием пищи
    </button>
  );
};

export default ButtonCreatingMeal;