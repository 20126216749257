import React from 'react';
import { ReactComponent as OkIcon } from '../myicon/modal_icon_ok.svg';


const ModalAddMealOk = () => {

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <OkIcon />
        <p>Прием пищи создан</p>
      </div>
    </div>
  );
};

export default ModalAddMealOk;