import React, { useState, useEffect, useRef } from 'react';
import './MealItem.css';



const MealItem = ({ time, type, calorie }) => {
    const TextCalorie = 'Ккал';

    return (
        <div className="meal-item-mi">
            <div className="item-mi">{time}</div>
            <div className="item-mi">{type}</div>
            <div className="item-mi">{calorie} {TextCalorie} </div>
        </div>
    );

}

export default MealItem ;