import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { fetchDataFromDatabase } from '../DataService';
import BackButton from '../Button/BackButton';

import MealItem from './MealItem';

import { ReactComponent as Img_Chevron_Down } from '../myicon/chevron_down_icon.svg';
import { ReactComponent as Img_Chevron_Up } from '../myicon/chevron_up_icon.svg';
import { ReactComponent as DelCircle } from '../myicon/del_circle.svg';
import { ReactComponent as Ico_Calendar } from '../myicon/calendar.svg';
// import Calendar from 'react-calendar';
// import DatePicker from "react-datepicker";
// import 'rsuite/dist/styles/rsuite-default.css'; 
import { DatePicker, Stack } from 'rsuite'; 
import { Button } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';

import 'rsuite/dist/rsuite.min.css';

import '../ProductDirectory/ProductSearch.css';
import './ListMeals.css';

// import "react-datepicker/dist/react-datepicker.css";
import { CustomProvider } from 'rsuite';


const ListMeals = ({iconMenuClick}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productInMeals = useSelector((state) => state.productInMeal);

    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const [result, setResult] = useState([]);
    const [expandedItems, setExpandedItems] = useState({});
    const [PageTitle, setPageTitle] = useState('Приемы пищи');
    const TextCalorie = 'Ккал';
    const TextProbel = ' ';
    const id_user = useSelector((state) => state.userId);
    
    useEffect(() => {
      const fetchMealData = async () => {
          try {
              const url = 'https://www.foodandsports.ru/api/mealgetall.php';
              const jsonData = JSON.stringify({ id_user: id_user });
              const data = await fetchDataFromDatabase(url, jsonData);
            
              // Сначала отсортируем данные по дате и времени
              const sortedData = data.sort((a, b) => {
                  if (a.date !== b.date) {
                      return a.date.localeCompare(b.date);
                  }
                  return a.time.localeCompare(b.time);
              });
  
              // Группировка данных сначала по дате, затем по времени
              const groupedData = sortedData.reduce((acc, curr) => {
                  const { id, date, time, type, calorie } = curr;
                  if (!acc[date]) {
                      acc[date] = [];
                  }
                  acc[date].push({ id, time, type, calorie});
                  return acc;
              }, {});
  
              // Создание результирующего массива с добавлением суммы калорий
              const result = Object.keys(groupedData).map(date => {
                  const meals = groupedData[date];
                  const totalCaloriesSum = meals.reduce((sum, meal) => sum + parseFloat(meal.calorie), 0);// Подсчет суммы калорий
                  const totalCalories = parseFloat(totalCaloriesSum).toFixed(2);
  
                  return {
                      date,
                      totalCalories, // Добавляем сумму калорий
                      meals,
                  };
              });
  
              console.log(result);
              setResult(result);
  
          } catch (error) {
              console.error('Error fetching meal data:', error);
          }
      };
  
      fetchMealData();
  
  }, [id_user]);

  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index]
    }));
}

function DateToDayOfWeek({ specificDate }) {
  const date = new Date(specificDate); // Создаем объект даты
  const dayOfWeek = date.toLocaleDateString('ru-RU', { weekday: 'long' }); // Получаем день недели
  const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

  return (
      <div>
          {capitalizedDayOfWeek }&nbsp;
      </div>
  );
}

function formatDate(inputDate) {
  const dateParts = inputDate.split('-'); // Разбиваем строку на части по знаку "-"
  const year = dateParts[0].slice(2); // Берем последние две цифры года
  const month = dateParts[1];
  const day = dateParts[2];

  return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
}


const handleClick = (item) => {
  // console.log(`Вы нажали на элемент с временем ${item.time} и калориями ${item.calorie}`);
  console.log('item)',item);
  // navigate(`/mainmenu/menumeals/editmeals?mealId=${item.id}`);
  navigate(`/mainmenu/menumeals/editmeals/${item.id}`, { state: item });

 
};

const handleCalendarClick = () => {
  console.log('Календарь');
}


return (
  <div className="app-container">
<div style={{ 
      display: 'block', width: 600, paddingLeft: 30 
    }}> 
<CustomProvider locale={ruRU}>

  <Stack >
  <DatePicker 
        style={{ width: 100 }} 
        placeholder="Select Date"
      /> 
  </Stack>
       </CustomProvider>
      
   </div>
  
<header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">{PageTitle}</p>
            <Ico_Calendar style={{ marginLeft: 'auto', paddingRight: '15px'}} onClick={handleCalendarClick} />
        </div>


</header>
    
<main>
{/* <Calendar
        onChange={setDate}
        value={date}
      /> */}

{/* <div>
         <h1>Пример использования RSuite</h1>
         <Button appearance="primary">Нажми на меня</Button>
       </div> */}


<CustomProvider locale={ruRU}>

<Stack >
<DatePicker 
      style={{ width: 100 }} 
      placeholder="Select Date"
    /> 
</Stack>
     </CustomProvider>

{result.map((item, index) => (
                 <div key={index}>
                     <div className="left-aligned-mlist" onClick={() => toggleExpand(index)}>
                         <DateToDayOfWeek specificDate={item.date}/>{formatDate(item.date)}
                         <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', fontSize: '16px', color: '#B889E2', width: '100%' }}>
                          ({item.totalCalories} {TextCalorie})
                      <div style={{ marginLeft: 'auto' }}>
                          {expandedItems[index] ? <Img_Chevron_Up /> : <Img_Chevron_Down />}
                      </div> 
                      </div>
                     
                     </div>
                     {expandedItems[index] && (
                 <ul>
                      {item.meals.map((meal, i) => (
                          <li key={i} className="li-style-mlist" onClick={() => handleClick(meal)}>
                         <MealItem time={meal.time} type={meal.type}  calorie={meal.calorie} />
                         <DelCircle style={{ marginRight: '0px' }} />
                         </li>
                     ))}
                 </ul>
              )}
                 </div>
             ))}
</main>  


</div>
);
};
export default ListMeals;