export const setGlobalValue = (value) => ({
    type: 'SET_GLOBAL_VALUE',
    payload: value,
  });

export const setUserId = (value) => ({
    type: 'SET_USER_ID',
    payload: value,
  });

export const setProductsInMeal = (value) => ({
    type: 'SET_PRODUCT_IN_MEAL',
    payload: value,
  });