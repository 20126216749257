import React from 'react';
import './ButtonWorkout.css'; 
import bg_img from './buttonpworkout_bg.svg';
// import {Img} from './img_products.jpg';
import { ReactComponent as Img } from './img_workout.svg';


const ButtonWorkout = ({ onClick, children }) => {
    return (
        <button 
            className="workout-button" 
            onClick={onClick} 
            style={{ 
                backgroundImage: `url(${bg_img})`, 
                display: 'flex', // Используем flex для управления элементами
                justifyContent: 'space-between', // Распределяем пространство между элементами
                alignItems: 'center', // Центрируем элементы вертикально
                padding: '10px', // Отступы для удобства
                border: 'none', // Убираем рамку
                
            }}> 
            {children}
            <div>
            <p style={{ 
                color: 'black', // Цвет текста
                fontSize: '22px', // Размер шрифта
                marginBottom: '5px', // Отступ снизу для переноса
                textAlign: 'left',
                paddingLeft: '10px',
                fontWeight: '510'
            }}>
                Тренировки
            </p>
        </div>
            <div style={{ marginLeft: 'auto', transform: 'translateY(10px)'}}>
                <Img />
                
            </div>
        </button>
    );
};

export default ButtonWorkout;