import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back} from '../myicon/ArrowLeft.svg';

// Стили для кнопки
const styles = {
    button: {
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      fontSize: '16px',
    //   padding: '10px',
    },
  };

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Переход на предыдущую страницу
  };

  return (
    <button onClick={handleBack} style={styles.button}>
    <Back />
    </button>
  );
};


export default BackButton;
