import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BottomMenu from '../Button/BottomMenu';
import BackButton from '../Button/BackButton';
import ButtonFeaturedProducts from '../Button/MenuProducts/ButtonFeaturedProducts';
import ButtonProductDirectory from '../Button/MenuProducts/ButtonProductDirectory';
import ButtonMyfood from '../Button/MenuProducts/ButtonMyfood';
import ButtonCreatefood from '../Button/MenuProducts/ButtonCreatefood';
import './MenuProducts.css';


function MenuProducts() {

  const navigate = useNavigate();

  const userId = useSelector((state) => state.userId);

  const handleClickProductDirectory = () => {
    navigate('/mainmenu/menuproducts/productsearch');
  };

  const handleClickProductFavorite = () => {
    navigate('/mainmenu/menuproducts/productfavorite');
  };
  

    return (
        <div className="app-container">

    <header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">Продукты и блюда</p>
        </div>
     </header>

     <main>
        <div style={{rowGap:'15px', gap: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', padding:'5px'}}>
            <ButtonFeaturedProducts onClick={handleClickProductFavorite}/>
            <ButtonProductDirectory onClick={handleClickProductDirectory}/>
            <ButtonMyfood />
            <ButtonCreatefood /> 
        </div>            
    </main>


     <footer>
           <BottomMenu/>
            
     </footer>

        </div>
        );

}

export default MenuProducts;