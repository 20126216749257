import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { setUserId } from '../actions';
// import { ReactComponent as Screen } from './myicon/splashscreen.svg';
import splashscreen from './myicon/splashscreen.jpg';
import { ReactComponent as BackArrow } from './myicon/left_icon.svg';
import './SplashScreen.css'; // Импортируем файл стилей



const SplashScreen = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    const id_user = urlParams.id_user;
    dispatch(setUserId (id_user));
    console.log("User ID:", id_user);
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/mainmenu");
    }, 2000); 

    return () => clearTimeout(timer); // очистить таймер при размонтировании
  }, [navigate]);

  return (
    <div className="centered-text-container"> 
      {/* <h1>Food&Sport</h1>  */}
      {/* <Screen/> */}
      <img src={splashscreen} alt="Splash screen" />
    </div>
  );
}

export default SplashScreen;