import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
// import { setUserId } from '../actions';

import BottomMenu from '../Button/BottomMenu';
import BackButton from '../Button/BackButton';
import './MenuWorkout.css';

// Стили для Header и содержимого
const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '10px',
  };

const contentStyle = {
    width: '100%', // Занимает всю ширину
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center', // Центрируем элементы по горизонтали
    alignItems: 'left', // Центрируем элементы по горизонтали
  };

const textStyle = {
    textAlign: 'center', // Центрируем текст
    fontSize: '24px',    // Размер шрифта 12
    margin: '0',         // Убираем отступы
    fontWeight: '500',
    // paddingBottom: '5px',
  };


function MenuWorkout() {
    return (
        <div className="app-container">

    <header style={headerStyle}>
        <div style={contentStyle}>
            <BackButton />
            <p style={textStyle}>Тренировки</p>
        </div>
     </header>

     <main>
        {/* <h1>Основной контент</h1> */}
     </main>


     <footer>
           <BottomMenu/>
            
     </footer>

        </div>
        );

}

export default MenuWorkout;