import React from 'react';
import './ButtonAddProduct.css'; // Импортируем файл стилей

const ButtonAddProduct = () => {
  return (
    <button className="button-addproduct">
      Добавить продукт
    </button>
  );
};

export default ButtonAddProduct;