import React from 'react';
import { ReactComponent as NoMassaIcon } from '../myicon/modal_ico_nomassa.svg';
// import './ModalNoMassa.css'; // Импортируем стили для модального окна

const ModalNoMassa = () => {

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <NoMassaIcon />
        <p>Введите массу продукта в граммах</p>
      </div>
    </div>
  );
};

export default ModalNoMassa;


