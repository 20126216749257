import React from 'react';
import { ReactComponent as OkIcon } from '../myicon/modal_icon_ok.svg';
// import './ModalNoMassa.css'; // Импортируем стили для модального окна

const ModalAddProductOk = () => {

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <OkIcon />
        <p>Продукт успешно добавлен в прием пищи</p>
      </div>
    </div>
  );
};

export default ModalAddProductOk;