import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Home } from '../myicon/Home.svg';
import { ReactComponent as HomeOutlined } from '../myicon/HomeOutlined.svg';

import { ReactComponent as FunctionalOutlined } from '../myicon/FunctionalOutlined.svg';
import { ReactComponent as Functional } from '../myicon/Functional.svg';

import { ReactComponent as ProfileOutlined } from '../myicon/ProfileOutlined.svg';
import { ReactComponent as Profile } from '../myicon/Profile.svg';


import './BottomMenu.css';

const BottomNav = () => {
  const location = useLocation(); // Получаем текущий маршрут

  // Функция для определения, активен ли маршрут
  const isActive = (path) => {
      return location.pathname === path;
  };

  // Функция для рендеринга иконок в зависимости от активности маршрута
  const renderIcon = (path) => {
      if (isActive(path)) {
          switch (path) {
              case '/mainmenu':
                  return <Home className="icon active" />;
              case '/functional':
                  return <Functional className="icon active" />;
              case '/profile':
                  return <Profile className="icon active" />;
              default:
                  return <Home className="icon active" />;
          }
      } else {
          switch (path) {
              case '/mainmenu':
                  return <HomeOutlined className="icon inactive" />;
              case '/functional':
                  return <FunctionalOutlined className="icon inactive" />;
              case '/profile':
                  return <ProfileOutlined className="icon inactive" />;
              default:
                  return <HomeOutlined className="icon inactive" />;
          }
      }
  };

  return (
      <div className="bottom-nav">
          <Link to="/mainmenu" className="nav-item">
              {renderIcon('/mainmenu')}
              <div className={`label ${isActive('/mainmenu') ? 'active' : 'inactive'}`}>Главная</div>
          </Link>
          <Link to="/functional" className="nav-item">
              {renderIcon('/functional')}
              <div className={`label ${isActive('/functional') ? 'active' : 'inactive'}`}>Функционал</div>
          </Link>
          <Link to="/profile" className="nav-item">
              {renderIcon('/profile')}
              <div className={`label ${isActive('/profile') ? 'active' : 'inactive'}`}>Профиль</div>
          </Link>
      </div>
  );
};

export default BottomNav;